import './Commercial.css';

import publicIp from 'public-ip';
import QueryString from 'qs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Model,
  QuestionMatrixModel,
  QuestionSelectBase,
  SurveyModel
} from 'survey-core';

import Loading from '../../components/Loading/Loading';
import {
  useGetFirstJsonLazyQuery,
  useGetPolicyOptionsQueryStringDataLazyQuery
} from '../../generated/graphql';
import { filterNonFoxdenProfessions } from '../../utils/filterProfession';
import getUnderwritingUrlData, {
  UnderwritingUrlData
} from '../../utils/getUnderwritingUrlData';
import setMinMaxDateForEffectiveDate from '../../utils/setMinMaxDateForEffectiveDate';
import { Context, ContextValue } from '../../utils/store';
import { prefillValueByQueryString } from '../../utils/SurveyJSQuestion';
import { useFlags } from '../../utils/useFlags';
import ErrorPage from '../Error/Error';
import CommercialSurvey from './CommercialSurvey';

const parseAndPrefillParameters = (
  model: SurveyModel,
  parsedQueryString: QueryString.ParsedQs
) => {
  const {
    profession,
    country,
    partnercode,
    state,
    province,
    effectiveDate,
    brokerCode
  } = parsedQueryString;
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_Country_WORLD_EN',
    country
  );
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_Province_WORLD_EN',
    province
  );
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_State_WORLD_EN',
    state
  );
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_PrimaryProfession_WORLD_EN',
    profession
  );
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_PartnerCode_WORLD_EN',
    partnercode
  );
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_EffectiveDate_WORLD_EN',
    effectiveDate
  );
  prefillValueByQueryString(
    model,
    'BusinessInformation_100_BrokerCode_WORLD_EN',
    brokerCode
  );
  return;
};

const Commercial: React.FC = () => {
  const context: ContextValue | null = useContext(Context);
  const { allowDatePickerToPickDateUpToSixtyDays } = useFlags();
  if (context) {
    const { setProgress, setHeaderTitle } = context;
    setProgress(0);
    setHeaderTitle('');
  }

  const [urlData, setUnderwritingUrlData] = useState<UnderwritingUrlData>();
  const [urlDataIsLoading, setUrlDataIsLoading] = useState<boolean>(true);
  const location = useLocation();
  const [isEndorsementOrRenewal, setIsEndorsementOrRenewal] = useState<boolean>(
    false
  );

  const parsedQueryString = QueryString.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const [
    getPolicyOptionsQueryStringData,
    {
      data: getPolicyOptionsQueryStringDataDataResult,
      loading: getPolicyOptionsQueryStringDataLoading,
      error: getPolicyOptionsQueryStringDataError
    }
  ] = useGetPolicyOptionsQueryStringDataLazyQuery({
    fetchPolicy: 'network-only'
  });

  const [
    getFirstJsonQuery,
    { data: getJSONData, loading: isGetJsonLoading, error: getJSONError }
  ] = useGetFirstJsonLazyQuery({
    fetchPolicy: 'network-only'
  });
  const transactionType = useRef<string>('New Business');

  const {
    group,
    brokerCode,
    country,
    partnercode,
    foxquiltBrokerId,
    agencyBrokerId
  } = parsedQueryString;

  useEffect(() => {
    window.scrollTo(0, 0);

    getUnderwritingUrlData().then((data) => {
      setUrlDataIsLoading(false);
      setUnderwritingUrlData(data);
    });
  }, []);

  useEffect(() => {
    (async () => {
      const ipAddress = await publicIp.v4({
        fallbackUrls: ['https://ifconfig.co/ip']
      });
      if (!urlData || urlDataIsLoading) return;

      const { endorsement: endorsementData, renewal: renewalData } = urlData;
      const queryStringData = endorsementData || renewalData;
      if (queryStringData && typeof queryStringData === 'string') {
        transactionType.current = endorsementData ? 'Endorsement' : 'Renewal';
        getPolicyOptionsQueryStringData({
          variables: { ipAddress, encryptedData: queryStringData }
        });
        setIsEndorsementOrRenewal(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlData, urlDataIsLoading]);

  useEffect(() => {
    if (!urlData || urlDataIsLoading) return;
    if (isEndorsementOrRenewal && !getPolicyOptionsQueryStringDataDataResult)
      return;
    const { policyFoxdenId } =
      getPolicyOptionsQueryStringDataDataResult?.getPolicyOptionsQueryStringData ||
      {};
    const utcEffectiveDate = (() => {
      if (urlData?.effectiveDate) {
        return new Date(urlData.effectiveDate).toISOString().split('T')[0];
      }
      return new Date().toISOString().split('T')[0];
    })();
    const utcTransactionDate = new Date().toISOString().split('T')[0];
    getFirstJsonQuery({
      variables: {
        policyFoxdenId,
        effectiveDate: utcEffectiveDate,
        transactionDate: utcTransactionDate,
        timezone: new Intl.DateTimeFormat([], {
          timeZoneName: 'long'
        }).resolvedOptions().timeZone,
        transactionType: transactionType.current,
        country: urlData?.country,
        provinceOrState: urlData?.province || urlData?.state
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPolicyOptionsQueryStringDataDataResult, urlData, urlDataIsLoading]);

  if (
    isGetJsonLoading ||
    getPolicyOptionsQueryStringDataLoading ||
    urlDataIsLoading ||
    !getJSONData
  ) {
    return <Loading />;
  }

  if (getJSONError || !getJSONData) {
    throw new Error(
      `${getJSONError ? 'getJSON query error' : 'getJSONData should be ready'}`
    );
  }

  if (getPolicyOptionsQueryStringDataError) {
    return <ErrorPage error={getPolicyOptionsQueryStringDataError} />;
  }

  const {
    json,
    previousAnswers,
    policyStartDateStr,
    policyExpiryDateStr,
    isStateActive
  } = getJSONData.getFirstJSON;

  if (!json) {
    throw new Error('JSON is not ready before render the form');
  }

  const model = new Model(json);

  setMinMaxDateForEffectiveDate(
    model,
    policyStartDateStr,
    policyExpiryDateStr,
    transactionType.current,
    allowDatePickerToPickDateUpToSixtyDays
  );
  // for endorsement
  if (previousAnswers) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      BusinessInformation_100_EffectiveDate_WORLD_EN,
      ...previousAnswersWithoutEffectiveDate
    } = previousAnswers;

    model.setDataCore({
      ...model.data,
      ...previousAnswersWithoutEffectiveDate
    });
    model.runTriggers();
  }

  model.clearInvisibleValues = 'onHidden';

  // filter professions for external brokers
  const additionalProfessions = model.getQuestionByName(
    'BusinessInformation_100_SecondaryProfessionAdditional_WORLD_EN'
  ) as QuestionSelectBase;
  if (brokerCode) {
    const primaryProfession = model.getQuestionByName(
      'BusinessInformation_100_PrimaryProfession_WORLD_EN'
    ) as QuestionSelectBase;
    const primaryProfessionChoices = primaryProfession.choices;
    const additionalProfessionsChoices = additionalProfessions.choices;
    primaryProfession.choices = filterNonFoxdenProfessions(
      primaryProfessionChoices
    );
    additionalProfessions.choices = filterNonFoxdenProfessions(
      additionalProfessionsChoices
    );
  }

  // set isStateActive for external brokers
  if (country === 'United States of America') {
    model.setVariable('isStateActive', isStateActive);
  }

  model.setVariable(
    'isBroker',
    foxquiltBrokerId || agencyBrokerId ? true : false
  );
  // prefill query string
  parseAndPrefillParameters(model, parsedQueryString);
  // prefill profession choices

  const percentagesQuestion = model.getQuestionByName(
    'BusinessInformation_100_ProfessionsPercentage_WORLD_EN'
  ) as QuestionMatrixModel;
  percentagesQuestion.rows = additionalProfessions.choices;

  if (typeof group !== 'string' && group !== undefined) {
    throw new Error('Unreachable - group type error');
  }

  if (typeof brokerCode !== 'string' && brokerCode !== undefined) {
    throw new Error('Unreachable - broker code type error');
  }

  if (typeof partnercode !== 'string' && partnercode !== undefined) {
    throw new Error('Unreachable - broker code type error');
  }

  return (
    <div className="h-full Commercial" data-testid="Commercial">
      <CommercialSurvey
        model={model}
        groupName={group}
        brokerCode={brokerCode}
        partnercode={partnercode}
        queryStringData={
          getPolicyOptionsQueryStringDataDataResult?.getPolicyOptionsQueryStringData
        }
        urlData={urlData}
        transactionType={transactionType.current}
        isStateActive={isStateActive}
      />
    </div>
  );
};

export default Commercial;
