import '../../CommonCommericalSurvey/CustomProperties';
import '../../CommonCommericalSurvey/CustomWidgets';
import '../../CommonCommericalSurvey/CommercialSurvey.css';

import { parseISO, startOfDay } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, useHistory } from 'react-router-dom';
import {
  QuestionHtmlModel,
  QuestionSelectBase,
  SurveyModel
} from 'survey-core';
import { Survey } from 'survey-react-ui';

import Banner from '../../../components/Banner/Banner';
import { DatePickerTypes } from '../../../components/DatePicker/DatePickerTypes';
import Loading from '../../../components/Loading/Loading';
import Modal from '../../../components/Modal/Modal';
import Navigation from '../../../components/Navigation/Navigation';
import {
  ApplicationResultKind,
  useCheckApplicationDateValidMutation,
  useCompleteApplicationMutation,
  useUpdateApplicationMutation
} from '../../../generated/graphql';
import { UnderwritingUrlData } from '../../../utils/getUnderwritingUrlData';
import { isLocal } from '../../../utils/isLocal';
import {
  onBlurEvent,
  QuestionFields,
  scrollToElement
} from '../../../utils/questionTracking';
import { Context, ContextValue } from '../../../utils/store';
import {
  formatDateAnswers,
  formatIsoDateString,
  getLabelFromChoicesByValue
} from '../../../utils/SurveyJSQuestion';
import { useFlags } from '../../../utils/useFlags';
import { ErrorPage } from '../..';
import { SurveyCSS } from '../../CommonCommericalSurvey/commonVariables';
import {
  trySetAmtrustBusinessAddressDefaults,
  trySetOwnerOfficerDefaults,
  useInjectWcQuestions
} from './wcHelper';

interface CommercialSurveyWithApplicationIdProps {
  applicationId: string;
  model: SurveyModel;
  groupName?: string;
  country: string;
  province: string;
  urlData?: UnderwritingUrlData;
  brokerCode?: string;
}

const CommercialSurveyWithApplicationId: React.FC<CommercialSurveyWithApplicationIdProps> = (
  props
) => {
  const history = useHistory();
  const context: ContextValue | null = useContext(Context);
  const {
    applicationId,
    model,
    groupName,
    country,
    urlData,
    province,
    brokerCode
  } = props;
  const [pageName, setPageName] = useState(model.currentPage.name);
  const [isWcLoading, setWcLoading] = useState(false);
  const flags = useFlags();
  const [question_start_time, setQuestionTime] = useState(new Date());
  sessionStorage.setItem('Province_state', province);

  let scrolling_target: HTMLElement | null = null;

  useEffect(() => window.scrollTo(0, 0), [pageName]);
  const [isPrev, setPrev] = useState(!model.isFirstPage);
  const [isNext, setNext] = useState(!model.isLastPage);
  const [isComplete, setComplete] = useState(false);
  const [isRedirectToQuote, setRedirectToQuote] = useState(false);
  const [isApplicationDateValid, setApplicationDateValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState<null | string>(null);
  const [cookies] = useCookies(['hubspotutk']);
  const hubspotTracker = cookies.hubspotutk;
  const QuestionTrackingData: QuestionFields = {
    questionId: null,
    questionAnswer: null,
    hubspotTracker: hubspotTracker
  };

  const [
    checkApplicationDateValid,
    {
      loading: isCheckApplicationDateValidLoading,
      error: isCheckApplicationDateValidError
    }
  ] = useCheckApplicationDateValidMutation();

  const [
    updateApplicationMutation,
    { loading: isUpdateApplicationLoading, error: updateApplicationError }
  ] = useUpdateApplicationMutation();

  const [
    completeApplicationMutation,
    { loading: isCompleteApplicationLoading, error: completeApplicationError }
  ] = useCompleteApplicationMutation();

  const injectWcQuestions = useInjectWcQuestions(model, applicationId);

  // --------- end of React hooks declaration

  if (isLocal) {
    // eslint-disable-next-line no-console
    console.log(
      'logging model values from src/pages/PrototypeCommercialWithApplicationId/CommercialSurveyWithApplicationId/index.tsx',
      model.getAllValues()
    );
  }

  // TODO: add postal code validation if needed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValidateQuestion = (survey: SurveyModel, options: any) => {
    const { value } = options;
    switch (options.name) {
      // we need to validate the date ma
      case 'BusinessInformation_100_EffectiveDate_WORLD_EN':
        const effectiveDateQuestion = survey.getQuestionByName(
          'BusinessInformation_100_EffectiveDate_WORLD_EN'
        ) as DatePickerTypes;
        const minDateObject = effectiveDateQuestion.minDate;
        const maxDateObject = effectiveDateQuestion.maxDate;
        if (
          !(minDateObject instanceof Date) ||
          !(maxDateObject instanceof Date)
        ) {
          break;
        }
        const minDateStartDay = startOfDay(minDateObject);
        const maxDateStartDay = startOfDay(maxDateObject);
        const selectedDateStartDay = startOfDay(
          parseISO(formatIsoDateString(value))
        );

        if (
          selectedDateStartDay < minDateStartDay ||
          selectedDateStartDay > maxDateStartDay
        ) {
          options.error = 'Invalid date - please select a day in range';
        }
        break;
      case 'BusinessInformation_100_BusinessAddress_WORLD_EN':
        const { postalCode } = value;
        // REGEX Values retrieved from graphql-scalar library PostalCode regex
        // https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/PostalCode.ts
        if (country === 'Canada') {
          if (
            !new RegExp(
              /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/
            ).test(postalCode)
          ) {
            options.error = 'Invalid postal code';
          }
        } else if (country === 'United States of America') {
          if (!new RegExp(/^\d{5}([-]?\d{4})?$/).test(postalCode)) {
            options.error = 'Invalid zip code';
          }
        } else {
          options.error = 'Invalid Country';
        }
        break;
      default:
      // no-ops
    }
  };

  // We need to update page state when page changed
  const onCurrentPageChanged = () => {
    setPrev(!model.isFirstPage);
    setNext(!model.isLastPage);
  };

  const onAfterRenderPage = () => {
    const { name: pageName } = model.currentPage;
    setPageName((currPageName: string) => {
      if (pageName && currPageName !== pageName) {
        return pageName;
      }
      return currPageName;
    });
  };

  const checkApplicationDate = async () => {
    const { data: isDateValid } = await checkApplicationDateValid({
      variables: {
        applicationId
      }
    });

    if (!isDateValid) {
      return <ErrorPage error={isCheckApplicationDateValidError} />;
    }

    setApplicationDateValid(isDateValid.checkApplicationDateValid);
  };

  const switchToNextPage = async () => {
    const currentPage = model.currentPage;
    const surveyValidateResult = currentPage.hasErrors();
    const { data: originalData } = model;
    const { 'Reason Flag': reasonFlag } = originalData;
    // get address value and send to backend for every call
    const addressQuestion = model.getQuestionByName(
      'BusinessInformation_100_BusinessAddress_WORLD_EN'
    );

    // Remove trailing space from company name
    const companyName = model.getValue(
      'BusinessInformation_100_CompanyName_WORLD_EN'
    );
    model.setValue(
      'BusinessInformation_100_CompanyName_WORLD_EN',
      companyName.trim()
    );
    const {
      value: BusinessInformation_100_BusinessAddress_WORLD_EN
    } = addressQuestion;

    const howLongInBusinessQuestion = model.getQuestionByName(
      'BusinessInformation_100_BusinessHistory_WORLD_EN'
    );
    const {
      value: BusinessInformation_100_BusinessHistory_WORLD_EN
    } = howLongInBusinessQuestion;

    const amtrustBroker = model.getValue(
      'Amtrust_Coverage_Broker_901_WC_WORLD_EN'
    );
    const amtrustNotBroker = model.getValue(
      'Amtrust_Coverage_Munich_201_WC_WORLD_EN'
    );

    // TODO this should be moved to surveyjs when we can add default expressions
    if (
      currentPage.name === 'BusinessInformation_100' &&
      amtrustBroker &&
      amtrustNotBroker
    ) {
      const payrollValue = model.getValue(
        'BusinessInformation_100_9219PayrollOpenText_USD_WORLD_EN'
      );

      const isBroker = model.getValue('BusinessInformation_Broker');

      const amtrustQuestion = isBroker
        ? 'Amtrust_Coverage_Broker_901_WC_WORLD_EN'
        : 'Amtrust_Coverage_Munich_201_WC_WORLD_EN';

      const chooseCoverage = payrollValue > 0;

      if (model.getQuestionByName(amtrustQuestion)?.isVisible) {
        model.setValue(amtrustQuestion, {
          chooseCoverage,
          coverageLimit: ''
        });
      }
    }

    checkApplicationDate();

    // start calling backend only if surveyjs finish its validation
    if (!surveyValidateResult) {
      // update application for current apge
      const data = currentPage.getValue();
      // clear unused data
      Object.keys(data).forEach((questionName) => {
        // this isVisible check is not 100% corret
        if (!model.getQuestionByName(questionName).isVisible) {
          delete data[questionName];
        }
      });

      // format iso date string to 'yyyy-MM-dd'
      // original date string from data is UTC date string represents local time
      // eg. orgianl date string will be "2021-04-12T19:51:00.000Z", which is "2021-04-12 15:51 pm EDT"
      // the return formatted string will be in local time, "2021-04-12" EDT
      formatDateAnswers(data);

      const answersInfo = {
        applicationId,
        answers: {
          ...data,
          BusinessInformation_100_BusinessHistory_WORLD_EN,
          BusinessInformation_100_BusinessAddress_WORLD_EN
        },
        reasonFlag
      };
      //TODO: Send page name as well
      const { data: updateApplicationData } = await updateApplicationMutation({
        variables: {
          answersInfo,
          ...(hubspotTracker ? { hubspotTracker } : undefined),
          pageName: currentPage.name,
          ...urlData,
          groupName
        }
      });

      if (!updateApplicationData) {
        throw new Error('updateApplicationData should be ready');
      }
      const result = updateApplicationData.updateApplication;

      switch (result.__typename) {
        case 'ApplicationSuccess':
          // try to inject workers' compensation related questions *after* answers are updated in the backend
          setWcLoading(true);
          await injectWcQuestions();
          setWcLoading(false);

          model.nextPage();
          break;
        case 'ApplicationFailure':
          const { error } = result;
          setErrorMsg(error);
          break;
        case 'ApplicationBlocked':
          setComplete(true);
          const answersInfo = {
            applicationId: applicationId,
            answers: {
              ...data
            },
            isBrokerFlow: true,
            reasonFlag: 'exceeded application limit'
          };

          await completeApplicationMutation({
            variables: {
              answersInfo,
              pageName: model.currentPage.name,
              groupName
            }
          });

          break;
        default:
          setErrorMsg('Uh oh, something is wrong. Please try again.');
          break;
      }
    } else {
      currentPage.focusFirstErrorQuestion();
      scrollToElement(scrolling_target);
    }
  };

  const goNext = composeFunction(
    trySetAmtrustBusinessAddressDefaults(model, flags),
    trySetOwnerOfficerDefaults(model, flags),
    switchToNextPage
  );

  const goBack = () => {
    model.prevPage();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doOnValueChanged = (survey: SurveyModel, options: any) => {
    QuestionTrackingData.questionId = options.name;
    QuestionTrackingData.questionAnswer = options.value;

    setPrev(!model.isFirstPage);
    setNext(!model.isLastPage);
    const workExclusion = survey.getQuestionByName(
      'Munich_PersonalServices_9219_WorkExclusionText_WORLD_EN'
    ) as QuestionHtmlModel;
    if (workExclusion) {
      survey.setValue(
        'Munich_PersonalServices_9219_WorkExclusionText_WORLD_EN',
        workExclusion.html
      );
    }

    // update coverage start date or existing policy expire date
    switch (options.name) {
      // business requirement: if have a similar insurance policy = true, set default value of BusinessInformation_100_ExistingPolicyExpiry_WORLD_EN as same day as BusinessInformation_100_EffectiveDate_WORLD_EN
      case 'BusinessInformation_100_EffectiveDate_WORLD_EN':
        const existingPolicyExpire = survey.getValue(
          'BusinessInformation_100_ExistingPolicyExpiry_WORLD_EN'
        );
        if (!existingPolicyExpire) {
          survey.setValue(
            'BusinessInformation_100_ExistingPolicyExpiry_WORLD_EN',
            options.value
          );
        }
        break;
      // business requirement: if user fill policy expiry date but do not fill new policy start day, set default value of BusinessInformation_100_EffectiveDate_WORLD_EN as same day as BusinessInformation_100_ExistingPolicyExpiry_WORLD_EN
      case 'BusinessInformation_100_ExistingPolicyExpiry_WORLD_EN':
        const newPolicyStart = survey.getValue(
          'BusinessInformation_100_EffectiveDate_WORLD_EN'
        );
        if (!newPolicyStart) {
          survey.setValue(
            'BusinessInformation_100_EffectiveDate_WORLD_EN',
            options.value
          );
        }
        break;
      default:
      // no opps
    }

    const yearStarted = survey.getValue(
      'BusinessInformation_100_BusinessStarted_WORLD_EN'
    );

    if (yearStarted) {
      const yearStartedConverted = Number(yearStarted);

      const currentYear = model.getVariable('currentYear');

      const difference = currentYear - yearStartedConverted;

      const yearsInBusiness = (() => {
        if (difference === 0) {
          return '<1 year';
        } else if (difference === 1) {
          return '1 year';
        } else if (difference === 2) {
          return '2 years';
        } else if (difference === 3) {
          return '3 years';
        } else if (difference === 4) {
          return '4 years';
        } else {
          return '5+ years';
        }
      })();

      survey.setValue(
        'BusinessInformation_100_BusinessHistory_WORLD_EN',
        yearsInBusiness
      );
    }
  };

  const doComplete = async () => {
    const { data: originalData } = model;
    const {
      BusinessInformation_Broker,
      Munich_9219_QuoteFlag,
      BusinessInformation_100_BusinessAddress_WORLD_EN: address,
      BusinessInformation_100_CustomerInfo_WORLD_EN,
      BusinessInformation_100_State_WORLD_EN,
      Munich_PersonalServices_9219_WorkExclusionListHtml_WORLD_EN,
      'Reason Flag': reasonFlag,
      jsonVersionNumber
    } = originalData;
    const carrierPartner = model.getVariable('carrierPartner');

    const newRedirectToQuote =
      !BusinessInformation_Broker && Munich_9219_QuoteFlag;
    setRedirectToQuote(newRedirectToQuote);

    // search coverage limit questions and send to backend only when reaching quote page
    const availableCoverageLimitQuestions = Object.keys(originalData).filter(
      (variableName: string) =>
        variableName.match(/^Coverage_Munich_201_9219/) &&
        variableName.includes('Limit_')
    );
    const availableCoverageLimitAnswers = {} as Record<string, unknown>;
    if (newRedirectToQuote) {
      availableCoverageLimitQuestions.forEach((questionName) => {
        availableCoverageLimitAnswers[questionName] =
          originalData[questionName];
      });
    }

    // add some labels to answers as well
    const businessTypeQuestion = model.getQuestionByName(
      'BusinessInformation_100_BusinessType_WORLD_EN'
    ) as QuestionSelectBase;
    const { value: businessTypeValue } = businessTypeQuestion;
    const businessTypeLabel = getLabelFromChoicesByValue(
      businessTypeValue,
      businessTypeQuestion
    );

    // once the survey calls completeLastPage, it will clear invisible questions and unused questions
    const surveyValidateResult = model.completeLastPage();

    checkApplicationDate();

    // start calling backend only if surveyjs finish its validation
    if (surveyValidateResult) {
      const { data } = model;

      const {
        BusinessInformation_100_BusinessMailingConfirmation_WORLD_EN: isMailingAddressSame,
        Amtrust_Coverage_Munich_201_WC_WORLD_EN: amtrustCoverage,
        Amtrust_Coverage_Broker_901_WC_WORLD_EN: amtrustCoverageBroker
      } = data;

      // TODO: check why DBA name and amtrust payroll total are still in model
      // Remove Amtrust questions if WC was not chosen as a coverage
      if (
        !amtrustCoverage?.chooseCoverage &&
        !amtrustCoverageBroker?.chooseCoverage
      ) {
        Object.keys(data).forEach((questionName) => {
          // keep Amtrust coverage in answers
          if (
            questionName === 'Amtrust_Coverage_Munich_201_WC_WORLD_EN' ||
            questionName === 'Amtrust_Coverage_Broker_901_WC_WORLD_EN'
          )
            return;
          if (questionName.includes('Amtrust')) {
            delete data[questionName];
          }
        });
      }

      // format iso date string to 'yyyy-MM-dd'
      // original date string from data is UTC date string represents local time
      // eg. orgianl date string will be "2021-04-12T19:51:00.000Z", which is "2021-04-12 15:51 pm EDT"
      // the return formatted string will be in local time, "2021-04-12" EDT
      formatDateAnswers(data);

      data.businessTypeLabel = businessTypeLabel;

      // if mailing address is same as insured address, set the mailing address value as insured address
      if (isMailingAddressSame) {
        data.BusinessInformation_100_MailingAddress_WORLD_EN = address;
      }

      // add json version to complete answers
      data.jsonVersionNumber = jsonVersionNumber;

      // add carrier partner to complete answers
      data.carrierPartner = carrierPartner;

      if (!applicationId) {
        throw new Error('applicationId should be ready');
      }

      const isBrokerFlow = !!(
        BusinessInformation_Broker || !Munich_9219_QuoteFlag
      );

      const answersInfo = {
        applicationId: applicationId,
        answers: {
          ...data,
          BusinessInformation_100_State_WORLD_EN,
          BusinessInformation_100_CustomerInfo_WORLD_EN,
          Munich_PersonalServices_9219_WorkExclusionListHtml_WORLD_EN,
          ...availableCoverageLimitAnswers
        },
        isBrokerFlow,
        reasonFlag
      };
      const {
        data: completeApplicationData
      } = await completeApplicationMutation({
        variables: {
          answersInfo,
          pageName: model.currentPage.name,
          groupName
        }
      });
      if (!completeApplicationData) {
        throw new Error('completeApplicationData should be ready');
      }

      const { errors, kind } = completeApplicationData.completeApplication;

      switch (kind) {
        case ApplicationResultKind.DeclineBusiness:
        case ApplicationResultKind.SendToBroker:
          setRedirectToQuote(false);
          break;
        case ApplicationResultKind.OfferQuote:
          // we dont need to do anything for this kind
          break;
        case ApplicationResultKind.Error:
          throw new Error('Unreachable - errors should be handled before this');
        default:
          throw new Error('Unreachable - unexpected Application result state');
      }

      if (errors.length === 0) {
        setComplete(true);
      } else {
        throw new Error(errors.join(' - '));
      }
    } else {
      model.currentPage.focusFirstErrorQuestion();
      scrollToElement(scrolling_target);
    }
  };

  const closeErrorModal = () => {
    setErrorMsg(null);
  };

  const goToStart = () => {
    history.push(`/`);
  };

  if (
    isWcLoading ||
    isUpdateApplicationLoading ||
    isCompleteApplicationLoading ||
    isCheckApplicationDateValidLoading
  ) {
    return <Loading />;
  }

  if (!isApplicationDateValid) {
    const invalidDateMessage =
      'Application is no longer valid. Please start a new one.';
    return (
      <Modal onAction={goToStart} actionName="Start new application">
        <div>
          <p>{invalidDateMessage}</p>
        </div>
      </Modal>
    );
  }

  if (updateApplicationError || completeApplicationError || errorMsg) {
    const graphqlErrorMsg =
      updateApplicationError || completeApplicationError
        ? 'Uh oh, something is wrong. Please try again.'
        : undefined;
    return (
      <Modal onAction={closeErrorModal}>
        <div className="text-primary">
          <p>{graphqlErrorMsg || errorMsg}</p>
        </div>
      </Modal>
    );
  }

  if (isComplete) {
    if (isRedirectToQuote)
      return (
        <Redirect
          to={{
            pathname: `/quote/${applicationId}`
          }}
        />
      );
    return (
      <Redirect
        to={{
          pathname: '/complete',
          state: {
            country,
            province
          }
        }}
      />
    );
  }

  const onScrollingElementToTop = (
    _sender: unknown,
    options: { elementId: string }
  ) => {
    scrolling_target = document.getElementById(options.elementId);
  };

  const currentPage = model.currentPageNo + 1;
  const totalPages = model.visiblePageCount + 1;

  const percentage = (currentPage / totalPages) * 100;

  if (context) {
    const { setProgress, setHeaderTitle } = context;
    setProgress(percentage);
    setHeaderTitle('');
  }

  const brokerProfession = model.getValue(
    'BusinessInformation_ProfessionIneligible'
  );

  // Banner should be visible on 2nd page, if profession is not bind online, and is an agent/broker
  const isVisible =
    pageName === 'BusinessInformation_100' && brokerProfession && !!brokerCode;

  const message =
    'Please note: One or more operation(s) is not eligible for Foxden. Application will be declined.';

  return (
    <div>
      <Banner isVisible={isVisible} message={message} />
      <div
        data-testid="Commercial"
        className="survey-container"
        onFocus={() => {
          setQuestionTime(new Date());
        }}
        onBlur={() => {
          onBlurEvent(QuestionTrackingData, question_start_time);
        }}
      >
        <Survey
          model={model}
          css={SurveyCSS}
          onValueChanged={doOnValueChanged}
          onValidateQuestion={onValidateQuestion}
          onCurrentPageChanged={onCurrentPageChanged}
          onAfterRenderPage={onAfterRenderPage}
          onScrollingElementToTop={onScrollingElementToTop}
        />
        <Navigation
          goBack={isPrev ? goBack : undefined}
          goNext={isNext ? goNext : undefined}
          doComplete={!isNext ? doComplete : undefined}
        />
      </div>
    </div>
  );
};

function composeFunction(...fns: Array<() => Promise<void>>) {
  return async () => {
    for (const fn of fns) {
      await fn();
    }
  };
}

export default CommercialSurveyWithApplicationId;
