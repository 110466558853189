import './styles/main.css';
import './index.css';
import './sentry';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';

import { getBackendApolloLinks } from './apolloLinks';
import App from './App';
import * as serviceWorker from './serviceWorker';
import getEnv from './utils/getEnv';

const { REACT_APP_LAUNCH_DARKLY_CLIENT_KEY } = getEnv();

// To use payment cilent:
// useQuery(QUERY, { variables, context: { clientName: PAYMENT_CLIENT } })
const apolloClient = new ApolloClient({
  link: getBackendApolloLinks(),
  cache: new InMemoryCache()
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_KEY
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
