import './Quote.css';

import {
  VersionControllerClient,
  WorkflowVersion
} from '@foxden/version-controller-client';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { z } from 'zod';

import { useAuth } from '../../auth/AuthProvider';
import { PaymentClient } from '../../backend-client/paymentBackend';
import { PaymentGraphqlClient } from '../../backend-client/paymentGraphqlBackend';
import { RatingQuotingClient } from '../../backend-client/ratingQuotingBackend';
import Loading from '../../components/Loading/Loading';
import {
  AdditionalInsured as AdditionalInsuredInfo,
  AmtrustEligibleQuoteResponse,
  AmtrustIneligibleQuoteResponse,
  AmtrustResetCoverageRecommendationInput,
  CoveragesData,
  MunichIneligibleQuoteResponse,
  MunichResetCoverageRecommendationInput,
  UpdateCoverageInput,
  UpdateCoverageOutput,
  useGetQuotePageRedirectQuery
} from '../../generated/graphql';
import { UserPaymentPlanOptionByQuoteId } from '../../generated/payment-graphql';
import {
  GetQuoteExpired,
  MunichEligibleQuoteResponse,
  QuoteInfo
} from '../../generated/rating-quoting-graphql';
import getEnv from '../../utils/getEnv';
import {
  getVersionedCompleteUrl,
  getVersionedTermsUrl
} from '../../utils/getVersionedUrlFromPaymentFrontend';
import { Context, ContextValue } from '../../utils/store';
import { useFlags } from '../../utils/useFlags';
import ErrorPage from '../Error/Error';
import AdditionalInsured from './components/AdditionalInsured';
import BuyNowButton from './components/BuyNowButton';
import Coverages from './components/Coverages';
import CustomerAgreement from './components/CustomerAgreement';
import FeinInputBox from './components/FeinInputBox';
import Premium from './components/Premium';
import { ReturnToQuotePopup } from './components/ReturnToQuotePopup/ReturnToQuotePopup';
import Summary from './components/Summary';
import { translateStringToLimit } from './translateStringToLimit';

export interface CoveragesDataInput {
  name: string;
  chooseCoverage: boolean;
  finalPremium: number;
  limits: Record<string, number>;
}

interface QuoteProps {
  applicationId: string;
  quoteInfo?: QuoteInfo;
  munichQuote?: MunichEligibleQuoteResponse | MunichIneligibleQuoteResponse;
  amtrustQuote?: AmtrustIneligibleQuoteResponse | AmtrustEligibleQuoteResponse;
  returnToQuoteExpired?: GetQuoteExpired;
  versionData?: WorkflowVersion;
}

const additionalInsuedInfoSchema = z.object({
  name: z.string().min(1),
  address: z.object({
    street: z.string().min(1),
    postalCode: z.string().min(5),
    province: z.string().min(2),
    city: z.string().min(1),
    address2: z.string().nullable().optional()
  }),
  email: z
    .string()
    .refine((value) => value === '')
    .or(z.string().email())
});

const { REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL } = getEnv();

const vcc = new VersionControllerClient(
  REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
);

// 2 cases for this component
// GetQuoteOk: quoteInfo will be defined
// GetQuoteExpired: returnToQuoteExpired will be defined

const Quote: React.FC<QuoteProps> = (props) => {
  const context: ContextValue | null = useContext(Context);

  const {
    amtrustQuote,
    applicationId,
    munichQuote,
    returnToQuoteExpired,
    quoteInfo,
    versionData
  } = props;

  const { isAuthenticated, isLoading: isAuthProviderLoading } = useAuth();

  const flags = useFlags();
  const { amTrustFullFlow } = flags;

  const closePopup = () => {
    window.location.reload();
  };

  const isCustomerAgreementActive = context
    ? context.isCustomerAgreementActive
    : false;
  const setCustomerAgreementActive = context
    ? context.setCustomerAgreementActive
    : undefined;
  const [munichQuoteResponse, setMunichQuoteResponse] = useState(munichQuote);
  const [amtrustQuoteResponse, setAmtrustQuoteResponse] = useState(() => {
    return amtrustQuote?.__typename === 'AmtrustEligibleQuoteResponse'
      ? amtrustQuote
      : undefined;
  });
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  // TODO: remove this state because both getQuoteOk and getQuoteExpired will return quoteId now, use the return type to differenciate in future
  const [shouldWaitForQuoteId, setWaitForQuoteId] = useState<boolean>(false);

  const [premium, setPremium] = useState(() => {
    const munichPremium =
      munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse'
        ? munichQuoteResponse?.premium ?? 0
        : 0;
    const amtrustPremium = getAmTrustPremium(amtrustQuoteResponse);

    return flags.amTrustFullFlow
      ? munichPremium + amtrustPremium
      : munichPremium;
  });

  const [additionalInsuredInfoList, setAdditionalInsuredInfoList] = useState<
    AdditionalInsuredInfo[] | undefined
  >(undefined);
  const [
    fillAllAdditionalInsuredInfo,
    setFillAllAdditionalInsuredInfo
  ] = useState<boolean | undefined>(undefined);
  let additionalInsuredCoverage: CoveragesData | undefined = undefined;
  if (munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse') {
    munichQuoteResponse?.coveragesData.forEach((coverage) => {
      if (coverage.name === 'additionalInsured') {
        additionalInsuredCoverage = coverage;
        return;
      }
    });
  }
  const enableAdditionalInsured =
    munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse' &&
    additionalInsuredCoverage;

  const isEndorsement = quoteInfo?.transactionType === 'Endorsement';

  const [
    updateCoverageInput,
    setUpdateCoverageInput
  ] = useState<UpdateCoverageInput>();
  const [
    updateCoverageData,
    setUpdateCoverageData
  ] = useState<UpdateCoverageOutput>();
  const [
    isUpdateCoverageByRatingQuotingLoading,
    setIsUpdateCoverageByRatingQuotingLoading
  ] = useState<boolean>(false);
  const [updateCoverageError, setUpdateCoverageError] = useState();

  const [
    resetRecommendationData,
    setResetRecommendationData
  ] = useState<UpdateCoverageOutput>();
  const [
    isResetCoverageRecommendationByRatingQuotingLoading,
    setIsResetCoverageRecommendationByRatingQuotingLoading
  ] = useState<boolean>(false);
  const [resetRecommendationError, setResetRecommendationError] = useState();

  const [quoteId, setQuoteId] = useState<string | undefined>(undefined); // this quoteId is getting from getQuoteOK response

  const [paymentOptionData, setPaymentOptionData] = useState<
    Array<UserPaymentPlanOptionByQuoteId>
  >();
  const [
    isGetUserPaymentPlanOptionByQuoteIdLoading,
    setIsGetUserPaymentPlanOptionByQuoteIdLoading
  ] = useState<boolean>(false);
  const [
    getUserPaymentPlanOptionByQuoteIdError,
    setGetUserPaymentPlanOptionByQuoteIdError
  ] = useState();
  const [
    paymentGraphqlClient,
    setPaymentGraphqlClient
  ] = useState<PaymentGraphqlClient | null>(null);

  // useQuery tries reading data from cache instead of making a new request when re-rendering.
  // TODO: getQuote API in src/pages/Quote/index.tsx already retrieved the application object, better to expand the query there instead of having this new useGetQuotePageRedirectQuery.
  const {
    loading: isGetQuotePageRedirectLoading,
    error: getQuotePageRedirectError,
    data: getQuotePageRedirectData
  } = useGetQuotePageRedirectQuery({
    variables: { applicationId }
  });

  const [isLoadingTermsUrl, setLoadingTermsUrl] = useState<boolean>(false);
  const [getTermsUrlError, setGetTermsUrlError] = useState<string | null>();
  const [isLoadingCompleteUrl, setLoadingCompleteUrl] = useState<boolean>(
    false
  );
  const [getCompleteUrlError, setGetCompleteUrlError] = useState<
    string | null
  >();

  const [isTnCLinkSent, setIsTnCLinkSent] = useState<boolean>(false);

  useEffect(() => {
    if (quoteId && versionData) {
      const client = new PaymentGraphqlClient(
        versionData.paymentBackendVersion,
        quoteId
      );
      setPaymentGraphqlClient(client);
      setWaitForQuoteId(false);
    }
  }, [quoteId, versionData]);

  useEffect(() => {
    /**
     * Redirect for New Business:
     *  1. paid & unsent: impossible
     *  2. paid & unsigned: for brokers: redirect to new thank you page; for client: redirect to T&C page
     *  3. paid & signed: redirect to thank you page
     *  4. unpaid & unsent: existing code
     *  5. unpaid & unsigned: for brokers: navigate to Client & Agreement (not expired); for client: redirect to T&C page
     *  6. unpaid & signed: redirect to invoice page
     *
     */

    const proceedToTnCPage = (quoteId: string) => {
      setLoadingTermsUrl(true);
      getVersionedTermsUrl(quoteId, vcc)
        .then((termsUrl) => {
          window.location.href = termsUrl;
        })
        .catch((error) => setGetTermsUrlError(error))
        .finally(() => setLoadingTermsUrl(false));
    };

    const proceedToPaymentCompletePage = (invoiceId: string) => {
      setLoadingCompleteUrl(true);
      getVersionedCompleteUrl(applicationId, invoiceId, vcc)
        .then((completeUrl) => {
          window.location.href = completeUrl;
        })
        .catch((error) => setGetCompleteUrlError(error))
        .finally(() => setLoadingCompleteUrl(false));
    };

    if (
      getQuotePageRedirectData &&
      getQuotePageRedirectData.getQuotePageRedirect
    ) {
      const {
        ownerData,
        policyData,
        quote,
        invoice,
        applicationData: { transactionType }
      } = getQuotePageRedirectData.getQuotePageRedirect;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (transactionType! === 'New Business') {
        const isTnCSigned = ownerData?.isDocuSignEnvelopeSigned ?? false;
        const isTncSent = ownerData?.isTnCLinkSent ?? false;
        setIsTnCLinkSent(isTncSent);
        const isPaid = !!policyData;
        if (isTnCSigned && isPaid) {
          // redirect to thank you page if signed & paid
          const {
            invoice: { _id: invoiceId }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          } = getQuotePageRedirectData.getQuotePageRedirect.policyData!; // isPaid = !!getQuotePageRedirectData.getQuotePageRedirect.policyData;
          proceedToPaymentCompletePage(invoiceId);
        } else if (isTnCSigned && !isPaid) {
          // redirect to invoice page if signed & unpaid
          if (paymentGraphqlClient && quoteId) {
            proceedToTnCPage(quoteId);
          } else if (returnToQuoteExpired) {
            setWaitForQuoteId(false); // if the quote is expired, no need to wait for the quoteId
          } else {
            setWaitForQuoteId(true); // if the quoteId or paymentGraphqlClient is not ready, we should wait.
          }
        } else if (!isTnCSigned && isPaid) {
          if (
            returnToQuoteExpired &&
            returnToQuoteExpired.isGracePeriodExpired
          ) {
            setWaitForQuoteId(false); // if grace period exceeds, should show quote expire screen and no need to wait getQuoteOK response
          } else {
            if (isAuthenticated) {
              // redirect to thank you page if unsigned & paid & brokers
              const {
                invoice: { _id: invoiceId }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              } = getQuotePageRedirectData.getQuotePageRedirect.policyData!; // isPaid = !!getQuotePageRedirectData.getQuotePageRedirect.policyData;
              proceedToPaymentCompletePage(invoiceId);
            } else {
              // redirect to T&C page if unsigned & paid & clients
              if (quoteId) {
                proceedToTnCPage(quoteId); // quoteId from getQuoteOK, means not expire,
              } else if (returnToQuoteExpired) {
                setWaitForQuoteId(false);
                proceedToTnCPage(returnToQuoteExpired.quoteId); // quote expire, we still want to proceed to T&C page
              } else {
                setWaitForQuoteId(true); // if the quoteId is not ready, we should wait.
              }
            }
          }
        } else if (!isTnCSigned && !isPaid && isTncSent) {
          if (!returnToQuoteExpired) {
            // If it's not expired, we should ...
            if (isAuthenticated) {
              // navigate to Client & Agreement if unpaid & unsigned & brokers
              if (setCustomerAgreementActive) {
                setCustomerAgreementActive(true);
              }
            } else {
              // redirect to T&C page if unsigned & unpaid & clients
              if (quoteId) {
                proceedToTnCPage(quoteId);
              } else {
                setWaitForQuoteId(true); // if the quoteId is not ready, we should wait.
              }
            }
          } else {
            setWaitForQuoteId(false); // if the quote is expired, no need to wait for the quoteId
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      } else if (transactionType! === 'Renewal') {
        if (invoice) {
          // If the invoice object is present, redirect to invoice page where the user will be redirected to the payment page
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const quoteId = quote!._id; // if the invoice exists, the quote object should also exist
          proceedToTnCPage(quoteId);
        }
      }
    }
  }, [
    getQuotePageRedirectData,
    quoteId,
    isAuthenticated,
    returnToQuoteExpired,
    setCustomerAgreementActive,
    applicationId,
    paymentGraphqlClient
  ]);

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    if (munichQuoteResponse) {
      // assign quoteId to state
      const id =
        munichQuoteResponse.__typename === 'MunichEligibleQuoteResponse'
          ? munichQuoteResponse.quoteId
          : undefined;
      setQuoteId(id);
      setWaitForQuoteId(false);

      const { region } = munichQuoteResponse;
      setCountry(region == 'Canada' ? 'Canada' : 'United States of America');
      // for now, consider "Canada" as province for data record, other US states will use individual state name
      setProvince(region);
    }
  }, [munichQuoteResponse, country, province]);

  useEffect(() => {
    const defaultAdditionalInsuredInfoList = quoteInfo?.additionalInsuredInfoList || [
      { name: '', address: undefined, email: '' }
    ];
    // only set the AdditionalInsured default value for valid cases
    if (enableAdditionalInsured && additionalInsuredCoverage?.chooseCoverage) {
      setAdditionalInsuredInfoList(defaultAdditionalInsuredInfoList);
    }
  }, [additionalInsuredCoverage, enableAdditionalInsured, quoteInfo]);

  useEffect(() => {
    if (resetRecommendationData) {
      const { munichOutput, amtrustOutput } = resetRecommendationData;
      if (munichOutput) {
        if (!munichQuoteResponse) {
          throw new Error('getQuote has no munich response');
        }
        if (munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse') {
          setMunichQuoteResponse({
            ...munichQuoteResponse,
            coveragesData: munichOutput.coveragesData,
            quoteId: munichOutput.quoteId,
            premium: munichOutput.finalPremium
          });
        }
      }

      if (amtrustOutput) {
        if (!amtrustQuoteResponse) {
          throw new Error('getQuote has no amtrust response');
        }

        const { premium, wcLimit } = amtrustOutput;

        if (!wcLimit) {
          throw new Error('wcLimit should be defined');
        }

        const limit = translateStringToLimit(wcLimit);

        if (
          amtrustQuoteResponse?.__typename === 'AmtrustEligibleQuoteResponse'
        ) {
          setAmtrustQuoteResponse({
            ...amtrustQuoteResponse,
            premium,
            limit
          });
        }
      }
      const amtrustPremium = amTrustFullFlow ? amtrustOutput?.premium ?? 0 : 0;
      setPremium(amtrustPremium + (munichOutput?.finalPremium ?? 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetRecommendationData]);

  useEffect(() => {
    if (updateCoverageInput && updateCoverageData) {
      const { amtrustOutput, munichOutput } = updateCoverageData;
      const amtrustPremium = (() => {
        if (updateCoverageInput.amtrustInput) {
          if (!amtrustQuoteResponse) {
            throw new Error('amtrust getQuote has no response');
          }
          if (!amtrustOutput) {
            throw new Error('updateCoverage has no amtrust response');
          }
          if (amtrustOutput.__typename !== 'AmtrustUpdateCoverageOutput') {
            throw new Error(
              'amtrust updateCoverage response is not AmtrustUpdateCoverageOutput'
            );
          }
          const { feinIsValid } = amtrustOutput;
          if (!amtrustOutput) {
            throw new Error('amtrust updateCoverage has no response');
          }
          setAmtrustQuoteResponse({
            ...amtrustQuoteResponse,
            ...(feinIsValid != null ? { feinIsValid } : {}), // only update FEIN/SSN if it's not null/undefined
            premium: amtrustOutput.premium,
            limit: updateCoverageInput.amtrustInput.wcLimit
          });
          return amtrustOutput.premium;
        }
        return getAmTrustPremium(amtrustQuoteResponse);
      })();

      const munichPremium = (() => {
        if (updateCoverageInput.munichInput) {
          if (!munichQuoteResponse) {
            throw new Error('getQuote has no munich Response');
          }
          if (!munichOutput) {
            throw new Error('updateCoverage has no munich response');
          }
          if (
            munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse'
          ) {
            setMunichQuoteResponse({
              ...munichQuoteResponse,
              coveragesData: munichOutput.coveragesData,
              quoteId: munichOutput.quoteId,
              premium: munichOutput.finalPremium
            });
            return munichOutput.finalPremium;
          }
        }
        return munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse'
          ? munichQuoteResponse?.premium ?? 0
          : 0;
      })();

      flags.amTrustFullFlow
        ? setPremium(amtrustPremium + munichPremium)
        : setPremium(munichPremium);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCoverageData]);

  useEffect(() => {
    if (!isEndorsement && quoteId !== undefined) {
      setIsGetUserPaymentPlanOptionByQuoteIdLoading(true);

      const paymentClient = new PaymentClient(
        versionData?.paymentBackendVersion
      );

      paymentClient
        .getUserPaymentPlanOptionByQuoteId(quoteId)
        .then((data) => {
          setPaymentOptionData(data);
          setIsGetUserPaymentPlanOptionByQuoteIdLoading(false);
        })
        .catch((err) => {
          setGetUserPaymentPlanOptionByQuoteIdError(err);
          setIsGetUserPaymentPlanOptionByQuoteIdLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId, versionData, isEndorsement]);

  const onUpdateCoverage = async (input: UpdateCoverageInput) => {
    setUpdateCoverageInput(input);

    // call backend to update coverage and get a new premium
    if (versionData) {
      setIsUpdateCoverageByRatingQuotingLoading(true);

      const ratingQuotingClient = new RatingQuotingClient(
        versionData.quoteBackendVersion
      );
      ratingQuotingClient
        .updateCoverage(input)
        .then((data) => {
          setUpdateCoverageData(data);
          setIsUpdateCoverageByRatingQuotingLoading(false);
        })
        .catch((err) => {
          setUpdateCoverageError(err);
          setIsUpdateCoverageByRatingQuotingLoading(false);
        });
    }
  };

  const onResetRecommendation = async () => {
    const munichInput: MunichResetCoverageRecommendationInput | undefined =
      munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse'
        ? {
            quoteId: munichQuoteResponse.quoteId,
            applicationId,
            region: munichQuoteResponse.region
          }
        : undefined;
    const amtrustInput:
      | AmtrustResetCoverageRecommendationInput
      | undefined = amtrustQuoteResponse
      ? { quoteId: amtrustQuoteResponse.amtrustquoteId }
      : undefined;

    if (versionData) {
      setIsResetCoverageRecommendationByRatingQuotingLoading(true);
      const ratingQuotingClient = new RatingQuotingClient(
        versionData.quoteBackendVersion
      );
      ratingQuotingClient
        .resetCoverageRecommendation({ amtrustInput, munichInput })
        .then((data) => {
          setResetRecommendationData(data);
          setIsResetCoverageRecommendationByRatingQuotingLoading(false);
        })
        .catch((err) => {
          setResetRecommendationError(err);
          setIsResetCoverageRecommendationByRatingQuotingLoading(false);
        });
    }
  };

  if (resetRecommendationError || updateCoverageError) {
    return <ErrorPage />;
  }

  if (
    isUpdateCoverageByRatingQuotingLoading ||
    isResetCoverageRecommendationByRatingQuotingLoading ||
    isGetUserPaymentPlanOptionByQuoteIdLoading ||
    (!quoteId && !returnToQuoteExpired) ||
    isAuthProviderLoading ||
    isGetQuotePageRedirectLoading ||
    shouldWaitForQuoteId ||
    isLoadingTermsUrl ||
    isLoadingCompleteUrl
  ) {
    return <Loading />;
  }

  if (getQuotePageRedirectError) {
    throw Error('GetQuotePageRedirect returned error');
  }

  if (!returnToQuoteExpired && getUserPaymentPlanOptionByQuoteIdError) {
    throw Error('Backend error');
  }

  if (getTermsUrlError) {
    throw Error(`Terms URL error: ${getTermsUrlError}`);
  }

  if (getCompleteUrlError) {
    throw Error(`Complete URL error: ${getCompleteUrlError}`);
  }

  const totalPayable = paymentOptionData
    ? paymentOptionData[0].totalPayable
    : premium;

  const showSendQuoteButton =
    munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse'
      ? munichQuoteResponse?.showSendQuoteButton
      : false;
  const quoteNumber =
    munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse'
      ? munichQuoteResponse?.quoteNumber
      : '';

  const validateAdditionalInsured = () => {
    let validataionResult = true;
    if (
      additionalInsuredCoverage?.['chooseCoverage'] &&
      additionalInsuredInfoList
    ) {
      additionalInsuredInfoList.forEach((info) => {
        try {
          additionalInsuedInfoSchema.parse(info);
        } catch (e) {
          setFillAllAdditionalInsuredInfo(false);
          validataionResult = false;
          return;
        }
      });
    }
    return validataionResult;
  };

  if (premium <= 0 && !isEndorsement && !returnToQuoteExpired) {
    return (
      <Redirect
        to={{
          pathname: '/complete',
          state: {
            country,
            province
          }
        }}
      />
    );
  }

  return (
    <div className="relative page-container font-raleway lg:my-4">
      {!returnToQuoteExpired ? (
        <div className="mt-1 quote-box">
          <div className="quote-header md:hidden text-center mb-2">
            <p className="text-2xl text-navy-dark font-bold">
              {isCustomerAgreementActive
                ? 'Client Agreements'
                : 'Tailored Quote For'}
              :
            </p>
            <p className="text-xl font-medium">{quoteInfo?.companyName}</p>
          </div>
          <Premium
            yearlyPremium={premium}
            isEndorsement={isEndorsement}
            paymentOptionData={paymentOptionData}
          />
          <Summary
            amtrustSummaryInput={amtrustQuoteResponse}
            munichSummaryInput={munichQuoteResponse}
            yearlyPremium={premium}
            totalPayable={totalPayable}
            isEndorsement={isEndorsement}
            carrierPartner={quoteInfo?.carrierPartner}
          />
          {isCustomerAgreementActive && quoteId ? (
            <CustomerAgreement
              quoteId={quoteId}
              customerName={quoteInfo?.customerName || ''}
              email={quoteInfo?.email || ''}
              paymentGraphqlClient={paymentGraphqlClient}
              isTnCLinkSent={isTnCLinkSent}
            />
          ) : (
            <Coverages
              munichCoverageInput={munichQuoteResponse}
              amtrustCoverageInput={amtrustQuote}
              onUpdateCoverage={onUpdateCoverage}
              onResetRecommendation={onResetRecommendation}
              isEndorsement={isEndorsement}
              applicationId={applicationId}
              carrierPartner={quoteInfo?.carrierPartner}
            />
          )}
          {amTrustFullFlow && amtrustQuoteResponse?.feinIsValid === false ? (
            <FeinInputBox
              amtrustQuoteInput={amtrustQuoteResponse}
              onUpdateCoverage={onUpdateCoverage}
            />
          ) : null}
          {munichQuoteResponse?.__typename === 'MunichEligibleQuoteResponse' &&
          additionalInsuredCoverage &&
          !isCustomerAgreementActive ? (
            <AdditionalInsured
              coverageData={additionalInsuredCoverage}
              onUpdateCoverage={onUpdateCoverage}
              region={munichQuoteResponse.region}
              quoteId={munichQuoteResponse.quoteId}
              applicationId={applicationId}
              additionalInsuredInfoList={additionalInsuredInfoList}
              setAdditionalInsuredInfoList={setAdditionalInsuredInfoList}
              fillAllAdditionalInsuredInfo={fillAllAdditionalInsuredInfo}
              setFillAllAdditionalInsuredInfo={setFillAllAdditionalInsuredInfo}
              disabled={munichQuoteResponse.disableAdditionalInsuredCheckbox}
            />
          ) : null}
          <BuyNowButton
            munichQuoteId={quoteId}
            amtrustQuoteId={Number(quoteId)}
            applicationId={applicationId}
            showSendQuoteButton={showSendQuoteButton}
            country={country}
            quoteInfo={quoteInfo}
            disabled={amTrustFullFlow && !amtrustQuoteResponse?.feinIsValid}
            setQuoteId={setQuoteId}
            additionalInsuredInfoList={additionalInsuredInfoList}
            validateAdditionalInsured={validateAdditionalInsured}
            versionData={versionData}
            quoteNumber={quoteNumber}
            isCustomerAgreementActive={isCustomerAgreementActive}
            isAuthenticated={isAuthenticated}
            isAuthProviderLoading={isAuthProviderLoading}
            paymentGraphqlClient={paymentGraphqlClient}
          />
        </div>
      ) : returnToQuoteExpired &&
        (returnToQuoteExpired.isQuoteExpired ||
          returnToQuoteExpired.isGracePeriodExpired) &&
        returnToQuoteExpired.quoteExpireDate ? (
        <ReturnToQuotePopup
          isQuoteExpired={true}
          applicationId={applicationId}
          returnToQuoteExpired={returnToQuoteExpired}
          closeAction={closePopup}
        />
      ) : returnToQuoteExpired &&
        returnToQuoteExpired.isEffectiveDateExpired &&
        returnToQuoteExpired.quoteExpireDate ? (
        <ReturnToQuotePopup
          isQuoteExpired={false}
          applicationId={applicationId}
          returnToQuoteExpired={returnToQuoteExpired}
          closeAction={closePopup}
        />
      ) : null}
    </div>
  );
};

export default Quote;

function getAmTrustPremium(
  quote?: AmtrustIneligibleQuoteResponse | AmtrustEligibleQuoteResponse
): number {
  if (quote?.__typename === 'AmtrustEligibleQuoteResponse') {
    return quote.premium ?? 0;
  }

  return 0;
}
